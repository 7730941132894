<template>
  <div class="d-flex flex-column mx-5" style="width: 100%">
    <div class="d-flex flex-row mt-5 w-100 justify-between align-center">
      <span class="page-title">Responsed Needs</span>
    </div>
    <div>
      <v-card v-for="need in needs" :key="need._id" @click="goToDetails(need)">
        <v-card-text class="d-flex flex-row my-3">
          <div>
            <v-img
              :src="require('@/assets/svg/needs.svg')"
              width="60"
              height="60"
              contain
            />
          </div>
          <div class="d-flex flex-column mx-3" style="flex-grow:1">
            <span style="font-size: 16px; font-family: 'Poppins-SemiBold';">
              {{ need.title }}
            </span>
            <div
              style="font-size: 12px;font-family: 'Poppins-Regular';"
              class="d-flex"
            >
              <div
                class="url-button"
                @click.stop="goToAgencyProfile(need.agency)"
                @mousedown.stop
                @touchstart.stop
              >
                {{ need.agency_name }}
              </div>
            </div>
            <span
              style="color: #5b2cae; font-size: 12px;font-family: 'Poppins-Regular';"
            >
              {{ need.duration }}
            </span>
          </div>
          <div style="flex-shrink: 0">
            <v-tooltip bottom v-if="profile._id == need.agency">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  @click.stop="onEdit(need)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              Edit Need
            </v-tooltip>
            <v-tooltip bottom v-if="profile._id == need.agency">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onDelete(need)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              Delete Need
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="ml-2"
                  small
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  @click.stop="onShare(need)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" size="18">mdi-share</v-icon>
                </v-btn>
              </template>
              Share Need
            </v-tooltip>
          </div>
        </v-card-text>
      </v-card>
      <div
        v-if="needs.length == 0"
        class="align-center justify-center d-flex mt-3 flex-column white--text w-100 h-100"
      >
        <div class="mb-3 mt-5">
          <v-img
            :src="require('@/assets/svg/empty-post.svg')"
            style="width: 60px; height: 50px"
            contain
          />
        </div>
        No matched needs
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return { needs: [], snackbar: false, snackMessage: null };
  },
  components: {},
  methods: {
    ...mapActions("need", {
      fetchVolunteerNeeds: "fetchVolunteerNeeds"
    }),
    showErrorMessage(message) {
      this.snackMessage = message;
      this.snackbar = true;
    },
    goToDetails(item) {
      this.$router.push({
        name: "agency-need-details",
        query: {
          _id: item._id
        }
      });
    },
    async onShare(need) {
      this.copyToClipboard(
        process.env.VUE_APP_WEB_URL + "/needs/details?_id=" + need._id
      );
      this.snackbar = true;
      this.snackMessage = "Copied share url";
    },
    copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    goToAgencyProfile(agency) {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: agency }
      });
    }
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile"
    })
  },
  mounted() {
    this.loading = true;
    this.loadCompleted = 0;
    this.fetchVolunteerNeeds(this.$route.params.id)
      .then(res => {
        this.loading = false;
        this.needs = res;
      })
      .catch(error => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  }
};
</script>
<style scoped>
.page-title {
  font-size: 20px;
  font-weight: 500;
  color: white;
}
</style>
